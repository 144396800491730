import TimelineIcon from '@mui/icons-material/Timeline';
import { Box, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { ComponentProps, createRef, useState } from 'react';
import "styled-components";
import { Gamer } from "../Models";
import { useStore } from '../State';
import GamerDetail from './GamerDetail';
import { Wowee, WoweePopover } from "./Wowee";

const Ladder = () => {
	const gamers = useStore(s => s.gamers)
	const page = useStore(s => s.page)
	const leagueTotal = useStore(s => s.availableGamers)

	return (
	<Box>
		<TableContainer component={Paper}>
			<Table className="ladderTable" cellPadding={'none'} size="small">
				<TableHead>
					<TableRow>
						<TableCell width="4em" align="left">Rank</TableCell>
						<TableCell>Gamer</TableCell>
						<TableCell padding={'none'}>Wowee</TableCell>
						<TableCell padding={'none'}>Recent</TableCell>
						<TableCell align="center">Total Uniques</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{gamers.map((gamer) => (
					<LadderRow key={gamer.AccountName} gamer={gamer}></LadderRow>
				))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							showFirstButton={true}
							showLastButton={true}
							rowsPerPageOptions={[50]}
							colSpan={8}
							count={leagueTotal()}
							rowsPerPage={50}
							page={page}
							onPageChange={(event, page) => {useStore.setState({page: page})}}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	</Box>)
}

const LadderRow = (args: {gamer: Gamer} & ComponentProps<typeof TableRow>) => {
	const [drawGraph, setDrawGraph] = useState(false)
	const rowRef = createRef<HTMLTableRowElement>()
	const gamer = args.gamer
	return (
		<>
		<TableRow ref={rowRef} {...args}>
			<TableCell align="center" component="th" scope="row">
				{gamer.rank}
			</TableCell>
			<TableCell><Link color="#fff" underline={'hover'} target="_blank" href={`//www.pathofexile.com/account/view-profile/${gamer.AccountName}`}>{gamer.AccountName}</Link></TableCell>
			<TableCell><Wowee items={gamer.BigItems} maxCount={12}/></TableCell>
			<TableCell><Wowee items={gamer.RecentItems} maxCount={6}/></TableCell>
			<TableCell align="center">{gamer.Score}</TableCell>
			<TableCell align="right">				
				<WoweePopover gamer={gamer} anchorTarget={rowRef}></WoweePopover>
				<IconButton
					size="large"
					aria-label="Show graph of uniques over time"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					color="inherit"
					onClick={() => setDrawGraph(!drawGraph)}>
					<TimelineIcon/>
				</IconButton>
			</TableCell>
		</TableRow>
		{ drawGraph === true &&
		<TableRow key={gamer.AccountName + " DETAILS"}>
			<TableCell colSpan={7}>
				<GamerDetail gamer={gamer}></GamerDetail>
			</TableCell>
		</TableRow>
		}
		</>
	)
}

export default Ladder

