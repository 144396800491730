import createState from 'zustand';
import { Gamer, League } from './Models';

type LadderState = {
	leagues: League[]
	league: League
	gamers: Gamer[]
	totalAvailableGamers: number
	searchQuery: string	
	page: number
	abortController: AbortController
	availableGamers: () => number
	updateId: () => string[]
}
function leaguefromuri() {
	return decodeURI(window.location.pathname.split('/')[1]) || "Loading ..."
}
const useStore = createState<LadderState>((set, get) => (
	{ 
		availableGamers: () => { 
			if(get().searchQuery.length > 0) 
				return get().gamers.length
			else 
				return get().league.count 
		},
		updateId: () => { 
			let returnId = ["0","0","0","0","0"];
			for(let l of get().leagues) {
				for(let i = 0;i < 5;i++) {
					if (parseInt(l.updateid[i]) > parseInt(returnId[i])) {
						returnId[i] = l.updateid[i]
					}
				}
			}
			return returnId
		},
		leagues: [],
		gamers: [],
		searchQuery: "",
		page: 0,
		totalAvailableGamers: 0,
		abortController: new AbortController(),
		league: {
			count: 0,
			isDead: false,
			isCurrent: false,
			isEvent: false,
			isPerma: false,
			isPrivate: false,
            isGauntlet: false,
			name: leaguefromuri(),
			relativeage: 0,
			updateid : ["0", "0", "0", "0", "0"], 
			isLeagueUnselected: leaguefromuri() == ""
		}
	}
));

export { useStore };
export type { LadderState };

