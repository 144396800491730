import { Container, Grid, TextField } from '@mui/material';
import { useEffect } from 'react';
import { getGamers, getLeagues, searchGamers } from "./Api";
import { League } from './Models';
import { Header } from './parts/Header';
import Ladder from "./parts/Ladder";
import { LeagueSelect } from './parts/leagueSelect';
import { useStore } from './State';

function useInitial() {
	var league = useStore(a => a.league)
	// Load all Leagues, and set active league to most populair league
	useEffect(() => {
		async function fetchMyAPI() {
			let leagues = await getLeagues()
			let newLeague : League = leagues.sort((a,b) => b.count - a.count)[0]
			if (!league.isLeagueUnselected) {
				newLeague = leagues.find(a => a.name == league.name) || newLeague
			}
			
			useStore.setState({ 
				totalAvailableGamers: league.count,	
				leagues: leagues,
				league: newLeague
			})
		}
		fetchMyAPI()
	}, [])
}
function useSearch() {
	// Search Query Hook
	let sq = useStore(a => a.searchQuery)
	useEffect(() => {
		if (sq.length <= 2)
			return;

		async function f() {
			let response = await searchGamers(useStore.getState().league.name, sq);
			useStore.setState({
				gamers: response, 
				totalAvailableGamers: response.length
			})
		}
		f()
	}, [sq])

	let league = useStore(a => a.league)
	useEffect(() => {
		useStore.setState({searchQuery: ""})
	}, [league.name])
}

function useLeagueLoad() {
	let league = useStore(a => a.league)
	let page = useStore(p => p.page)
	let sq = useStore(a => a.searchQuery)
	useEffect(() => {
		if (sq.length > 2) {
			return
		}
		if (league.isLeagueUnselected) {
			return
		}

		let f = async () => {
			let response = await getGamers(league!.name, page * 50, 50)
			response.forEach(a => {
				var child = Object.assign({}, a)
				child.children = undefined
				a.children = [child]
			})
			useStore.setState({gamers: response})

			window.history.pushState({}, league.name + " Unique Ladder", "/" + league.name);
		}
		f();
	}, [page, league.name, sq])
}

function App() {
	useInitial()
	useSearch()
	useLeagueLoad()
	
	let sq = useStore(a => a.searchQuery)

	return (
		<div id="app">
			<Header></Header>
			<Container maxWidth="xl">
				<Grid container spacing={2} maxWidth="xl" paddingBottom="80px" paddingTop="80px">
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={6}>
							<LeagueSelect></LeagueSelect>
						</Grid>
						<Grid item xs={6}>
							<TextField value={sq} fullWidth label="Name" type="search" 
								onChange={(event) => useStore.setState({searchQuery: event.target.value})}/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Ladder></Ladder>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default App;
