import { Box } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { getGamer } from "../Api";
import { Gamer } from "../Models";

const GamerDetail = (args: { gamer: Gamer }) => {
	const [gamer, updateGamer] = useState<Gamer>(args.gamer)
	gamer.Items = gamer.Items || []
	useEffect(() => {
		async function f() {
			let newgamer = await getGamer(gamer.AccountName, gamer.League)
			args.gamer.Items = newgamer.Items
			updateGamer(newgamer)
		};
		if (gamer.Items.length == 0) {
			f();
		}
	}, [args.gamer, gamer.AccountName, gamer.League, gamer.Items.length])

	
	return (
		<Box height="450px" width="98%" paddingTop="50px" display="block">
			<HighchartsReact
				highcharts={Highcharts}
				options={BuildHighChart(gamer)}
			/>
		</Box>
	)
}

function BuildHighChart(gamer : Gamer) {
	Highcharts.setOptions(Highcharts.theme);

	let chart: Highcharts.Options = {
		chart: {
			zoomType: 'x'
		},
		xAxis: {
			type: 'datetime'
		},
		yAxis: {
			title: {
				text: 'Uniques Found'
			}
		},
		title: {
			text: ""
		},
		legend: {
			enabled: true
		},
		annotations: [{
			labelOptions: {

			},
			labels: []
		}],
		series: []
	}

	var serie: Highcharts.SeriesOptionsType = {
		type: "line",
		name: gamer.AccountName,
		data: [],
	}
	var anno: Highcharts.AnnotationsOptions = {
		visible: true,
		labels: []
	}

	gamer.Items.sort((a, b) => (a.time - b.time));
	for (let i = 0; i < gamer.Items.length; i++) {
		let item = gamer.Items[i];
		let point = [item.time * 1000, i + 1];
		serie.data!.push(point);
	}

	if (gamer.BigItems) {
		for (let i = 0; i < gamer.BigItems.length; i++) {
			let item = gamer.BigItems[i]
			let a: Highcharts.AnnotationsLabelsOptions = {
				point: { x: item.time * 1000, y: (i + 1), xAxis: 0, yAxis: 0 },
				text: item.name
			};
			anno.labels!.push(a);
		}
		chart.annotations!.push(anno)
	}
	chart.series!.push(serie);
	if (document.getElementById("chart")) {
		Highcharts.chart("chart", chart);
	}
	return chart
}

Highcharts.theme = {
	"colors": ["#f1c40f", "#2ecc71", "#9b59b6", "#e74c3c", "#34495e", "#3498db", "#1abc9c", "#f39c12", "#d35400"],
	"chart": {
		"backgroundColor": "rgba(0,0,0,0)"
	},
	"xAxis": {
		"gridLineDashStyle": "Dash",
		"gridLineWidth": 1,
		"gridLineColor": "#46627f",
		"lineColor": "#46627f",
		"minorGridLineColor": "#BDC3C7",
		"tickColor": "#46627f",
		"tickWidth": 1,
		"title": {
			"style": {
				"color": "#FFFFFF"
			}
		}
	},
	"yAxis": {
		"gridLineDashStyle": "Dash",
		"gridLineColor": "#46627f",
		"lineColor": "#BDC3C7",
		"minorGridLineColor": "#BDC3C7",
		"tickColor": "#46627f",
		"tickWidth": 1,
		"title": {
			"style": {
				"color": "#FFFFFF"
			}
		}
	},
	"title": {
		"style": {
			"color": "#FFFFFF"
		}
	},
	"subtitle": {
		"style": {
			"color": "#666666"
		}
	},
	"legend": {
		"itemStyle": {
			"color": "#C0C0C0"
		},
		"itemHoverStyle": {
			"color": "#C0C0C0"
		},
		"itemHiddenStyle": {
			"color": "#444444"
		}
	}
};

export default GamerDetail