import React from 'react';
import "styled-components";
import { GamerItem } from "../Models";

function stopprop(e: any) { e.stopPropagation(); }

export const Item = (item: GamerItem, key: string) => {
	var poedbName = item.name.replaceAll(" ", "_").replaceAll("'", "");
	return (
		<a className="itemlink" href={`//poedb.tw/us/${poedbName}`} title={item.name} key={key} onClick={stopprop} target="_blank">
			<img alt={item.name} src={item.icon}/>
		</a>
	)
}