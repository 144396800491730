import InfoIcon from '@mui/icons-material/Info';
import { AppBar, AppBarProps, Button, Card, CardActions, CardContent, Container, IconButton, Paper, Popover, Toolbar, Typography } from '@mui/material';
import { useState, ReactComponentElement } from 'react';
import { useStore } from '../State';
import { ReactComponent as DiscordLogo } from './discord-logo-blue.svg';

export const Header = (args: AppBarProps) => {
	var [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	var updateId = useStore(l => l.updateId)
    

	return (<AppBar {...args} >
		<Container maxWidth="xl">
			<Toolbar>
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					Unique Collection Ladder
				</Typography>
				<IconButton
					size="large"
					aria-label="About and Info"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					onClick={(event) => setAnchorEl(event.currentTarget)}
					color="inherit"
					focusRipple={true}>
					<InfoIcon />
				</IconButton>
				<Popover 
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(anchorEl)}
					onClose={() => setAnchorEl(null)}
				>
					<Card style={{ width: "500px" }}>
						<CardContent>
							<div style={{ display: "block", marginBlock: "1em 1em", marginInline: "0px", marginTop: "0px" }}>
								<Typography gutterBottom variant="h6" component="div">
									Joining the Ladder
								</Typography>
								<Typography variant="body2" color="text.secondary">
									To join the ladder, you need an Unique Stash tab and set it to public.
								</Typography>
								<div>
									<Paper>
										<img src="https://cld.moe/f/PathOfExile_Hpnhwm2Vc0.png" 
											alt="Unique Ladder Enabling instruction" 
											width="100%"
											style={{ display: "block" }}></img>
									</Paper>
								</div>
							</div>
							<div style={{ display: "block", marginBlock: "1em 1em", marginInline: "0px", marginTop: "0px" }}>
								<Typography gutterBottom variant="h6" component="div">
									Missing Uniques :(
								</Typography>
								<Typography variant="body2" color="text.secondary">
									The ladder has a minimum delay of 15 minutes, if your uniques don't show up after 15 minutes, you can rename your unique stash to force an update.
								</Typography>
							</div>
							<div style={{ textAlign: 'center' }}>
								<Typography variant="caption" color="text.disabled">
									{updateId().reduce((a, b) => a + " " + b)}
								</Typography>
							</div>
						</CardContent>
						<CardActions>
                            <Button size="medium" href="https://discord.gg/kBsckeaPMQ" target="_blank">
                                <DiscordLogo width="200px"></DiscordLogo>
                            </Button>
							<form action="https://www.paypal.com/donate" method="post" target="_top" className="paypalform">
								<input type="hidden" name="hosted_button_id" value="86QMU6TKFHZA4" />
								<input className="paypalbutton" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
								<img alt="" src="https://www.paypal.com/en_NL/i/scr/pixel.gif" width="1" height="1" />
							</form>
						</CardActions>
					</Card>
				</Popover>				
			</Toolbar>
		</Container>
	</AppBar>)
}