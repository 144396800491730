import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import React from 'react';
import { League } from "../Models";
import { useStore } from '../State';


//const LadderRow = (args: { gamer: Gamer } & ComponentProps<typeof TableRow>)
export const LeagueTypeGroup = (fieldname: string, groupname: string) => {
    const leagues = useStore(s => s.leagues)
    const group = leagues.filter(l => l[fieldname] == true);

    if(group.length < 1)
        return []

    return [
        (<ListSubheader>{groupname} Leagues</ListSubheader>),
        group.map((l) => <MenuItem key={l.name} value={l.name}>{l.name}</MenuItem>)
    ]
}

export const LeagueSelect = () => {
	const leagues = useStore(s => s.leagues)
	const league = useStore(s => s.league)

	return (
		<FormControl fullWidth>
			<InputLabel id="league-select-label">League</InputLabel>
			<Select
				labelId="league-select-label"
				label="League"
				value={league.name}
				onChange={(event) => { 
                    useStore.setState({ league: leagues.find(l => l.name == (event.target.value as string))})
				}}
				fullWidth>
                    {leagues.length === 0 &&
                        <MenuItem key={league.name} value={league.name as string}>{league.name}</MenuItem>
                    }
                    { LeagueTypeGroup("isCurrent", "Current") }
                    {LeagueTypeGroup("isPerma", "Perma") }
                    {LeagueTypeGroup("isGauntlet", "Gauntlet")}
                    {LeagueTypeGroup("isEvent", "Event")}
                    {LeagueTypeGroup("isPrivate", "Private")}
			</Select>
		</FormControl>
	)
}