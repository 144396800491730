import ReadMoreIron from '@mui/icons-material/ReadMore';
import { Card, CardContent, Divider, IconButton, Popover, Typography, Modal } from '@mui/material';
import React, { RefObject, useEffect, useState } from 'react';
import "styled-components";
import { getGamer } from '../Api';
import { Gamer, GamerItem } from "../Models";
import { Item } from './Item';

export const Wowee = (args : {items: GamerItem[], maxCount: number}) => {
	var a = args.items.slice(0, args.maxCount).map((v, k) => Item(v, k.toString()))
	return (
		<div className="itemwrapper">{a}</div>
	)
}

export const WoweeItemGroup = (args: {items: GamerItem[], name: string}) => { 
	if (args.items.length > 0) {
		return (
		<div style={{padding:"20px"}}>
			<Typography>
				{args.name}
			</Typography>
			<Divider></Divider>
			<br></br>
			<div className="itemwrapper">
				{args.items.map((i, k) => Item(i,k.toString()))}
			</div>
		</div>)
	}
	return <></>
}

export const WoweeCard = (args: {gamer: Gamer}) => { 
	const [allItems, allItemsUpdate] = useState<GamerItem[]>([])

	useEffect(() => {
		async function f() {
			let newgamer = await getGamer(args.gamer.AccountName, args.gamer.League)
			allItemsUpdate(newgamer.Items)
		};
		f();
		
	}, [])

	return (
		<Card style={{width: "800px", margin: "50px auto auto auto"}}>
			<CardContent>
				<WoweeItemGroup name="Relics" items={allItems.filter(i => i.isRelic)}></WoweeItemGroup>
				<WoweeItemGroup name="DemiGods" items={allItems.filter(i => i.name.search("Demigod") != -1)}></WoweeItemGroup>
				<WoweeItemGroup name="Wowee" items={args.gamer.BigItems}></WoweeItemGroup>
			</CardContent>
		</Card>
	)
}


export const WoweePopover = (args: {anchorTarget: RefObject<HTMLTableRowElement>, gamer: Gamer}) => {
	var [anchorEl, setAnchorEl] = useState<null | HTMLTableRowElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(args.anchorTarget.current);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	return <>
		<IconButton
			size="large"
			aria-label="Show unique details"
			aria-controls="menu-appbar"
			aria-haspopup="true"
			color="inherit"
			onClick={handleClick}>
			<ReadMoreIron/>
		</IconButton>
		<Modal
			id={id}
			open={open}
			onClose={handleClose}>
			<WoweeCard gamer={args.gamer}></WoweeCard>
		</Modal>
	</>
}