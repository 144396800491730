import { Gamer, League } from "./Models"
import { useStore } from "./State"

function uri() {
	return new URL("", window.location.pathname)
}

async function api(path: URL, useAbort: boolean = false) {
	
	if (window.location.hostname === "localhost") {
		path.hostname = "localhost"
		path.port = "1000"
		path.protocol = "http"
	}

	if (useAbort) {
		useStore.getState().abortController.abort();
		useStore.setState({abortController: new AbortController()})
	}
	
	return fetch(path.toString(), {
		"headers": {
			"accept": "application/json",
		},
		"body": null,
		"method": "GET",
		signal: useAbort ? useStore.getState().abortController.signal : null
	}).then((r) => r.json())
}

async function getGamer(accountName: string, league: string): Promise<Gamer> {
	let url = new URL(document.URL);
	url.pathname = `/api/league/${league}/gamer/${accountName}`;
	var g = (await api(url)) as Gamer
	g.League = league
	return g
}

async function getLeagues(): Promise<League[]> {
	var leagues: League[] = await api(new URL("/api/league", document.URL))

	for (let league of leagues) {
		league.isDead = league.name == "SSF Standard" || league.name == "SSF Hardcore"
		league.isPrivate = league.name.match(/.*\(PL\d+\)/) != undefined
        league.isEvent = !league.isPrivate && (league.name.match(/.*\(.*\d+\)/) != undefined)
        league.isGauntlet = (!league.isPrivate && !league.isEvent && league.name.match(/.* Gauntlet/) != undefined) && (league.relativeage < 10_000_000)
		league.isPerma = (league.name == "Standard" || league.name == "Hardcore" || league.name == "Hardcore SSF" || league.name == "Solo Self-Found" || 
			league.name == "Ruthless" || league.name == "SSF Ruthless" || league.name == "Hardcore Ruthless" || league.name == "Hardcore SSF Ruthless" )
		league.isCurrent = !(league.isPerma || league.isEvent || league.isPrivate || league.isGauntlet || league.isDead) && (league.relativeage < 2_000_000)
	}

	return leagues.sort((a, b) => a.count - b.count).filter(l => !(l.isPrivate && l.count < 5));
}

async function getGamers(league: string, start: number, pageSize: number): Promise<Gamer[]> {
	let url = new URL(document.URL);
	url.pathname = `/api/league/${league}/ladder`;
	url.searchParams.append("start", start.toString())
	url.searchParams.append("end", (start + pageSize).toString())
	return api(url).then((g: Gamer[]) => {
		g.forEach((a, i) => { a.rank = i + start + 1; a.League = league })
		return g;
	})
}

async function searchGamers(league: string, q: string): Promise<Gamer[]> {
	let url = new URL(document.URL);
	url.pathname = `/api/league/${league}/search/${q}`;
	return api(url) as Promise<Gamer[]>
}

export { getGamer, getLeagues, getGamers, searchGamers }
